import React from "react";

import Column from "../../containers/Column";
import Input from "../../components/Input";
import Date from "../../components/Date";
import Select from "../../components/Select";
import Expand from "../../containers/Expand";
import { formatEnumerationData } from "../../helpers";
import locs from "../../localization";

import formValidation from "../../validations/formValidation";
import isEmail from "../../validations/isEmail";
import isDate from "../../validations/isDate";
import isPhoneNumber, { PHONE_PREFIX } from "../../validations/isPhoneNumber";
import isAdult from "../../validations/isAdult";
import isNotEmpty from "../../validations/isNotEmpty";
import isLength from "../../validations/isLength";
import { phoneNormalizer, phoneNumberFormatter, PHONE_MASK } from "../../formatters/phoneNumber";
import dateFormatter from "../../formatters/date";

import theme from "./theme.scss";
import { Position, TitlePrefix, TitleSuffix } from "../../constants/interfaces";
import { Field, InjectedFormProps, WrappedFieldProps } from "redux-form";
import { CalculatorValues } from "../../reducers/types";
import Checkbox from "../../components/Checkbox";
import isAlphaWithSpaces from "../../validations/isAlphaWithSpaces";
import isAfterDate from "../../validations/isAfterDate";

export function fullNameOnChange(values: CalculatorValues, dispatch, props: InjectedFormProps, previousValue: CalculatorValues) {
  if (
    values.contact.person?.name !== previousValue.contact?.person?.name ||
    values.contact.person?.surname !== previousValue.contact?.person?.surname
  ) {
    const nameParts = [values.contact.person?.name, values.contact.person?.surname].filter(x => !!x);
    dispatch(props.change("contact.person.fullName", nameParts.join(" ")));
  }

  // Clear title prefix / titles suffix after hide
  if (!values.contact.person?.isTitlePrefix && values.contact.person?.titlePrefixId) {
    dispatch(props.change("contact.person.titlePrefixId", ""));
  }

  if (!values.contact.person?.isTitleSuffix && values.contact.person?.titleSuffixId) {
    dispatch(props.change("contact.person.titleSuffixId", ""));
  }
}

// Interface used in validation, doesn't copy entire page data structure, only used fields
interface FormValues {
  contact: {
    person?: {
      name?: string;
      surname?: string;
    };
  };
}

export function validateSameNames(values: FormValues) {
  const firstName = values.contact?.person?.name;
  const surname = values.contact?.person?.surname;

  if (!firstName || !surname) {
    return {}; // No errors
  }

  if (firstName == surname) {
    return {
      contact: {
        person: {
          name: locs("errors.matchesWithSurname"),
          surname: locs("errors.matchesWithName"),
        },
      },
    };
  }

  return {}; // No errors
}

interface PersonProps {
  titlePrefixes: TitlePrefix[];
  titleSuffixes: TitleSuffix[];
  positions: Position[];
  showPosition: boolean;
  disabled: boolean;
}

export default function Person({
  titlePrefixes,
  titleSuffixes,
  positions,
  disabled = false,
  showPosition = false,
  input,
}: PersonProps & WrappedFieldProps) {
  const inputValue = input.value || {};
  const name = input.name;

  return (
    <div>
      <Column weights={["1_11", 1]}>
        <div>
          <Field
            name={`${name}.name`}
            id="givenName"
            autoComplete="given-name"
            type="text"
            component={Input}
            props={{
              disabled,
              theme,
            }}
            validate={
              !disabled && [
                formValidation(isNotEmpty),
                formValidation(isLength({ max: 40 })),
                formValidation(isAlphaWithSpaces, locs("errors.invalidName")),
              ]
            }
            label={locs("labels.name")}
          />
          {!disabled && (
            <Field
              name={`${name}.isTitlePrefix`}
              type="text"
              component={Expand}
              props={{
                theme: { baseContainer: theme.baseContainerExpandTitle },
                showLabel: locs("actions.add_title_before"),
                hideLabel: locs("actions.remove_title_before"),
              }}
            />
          )}
          {((disabled && inputValue.titlePrefixId) || inputValue.isTitlePrefix) && (
            <Field
              name={`${name}.titlePrefixId`}
              type="select"
              component={Select}
              props={{
                disabled,
                data: formatEnumerationData(titlePrefixes, "desc"),
                theme,
                showBlankOption: true,
              }}
              label={locs("labels.title_before")}
            />
          )}
        </div>
        <div>
          <Field
            name={`${name}.surname`}
            id="surname"
            autoComplete="family-name"
            type="text"
            component={Input}
            props={{
              disabled,
              theme,
            }}
            validate={
              !disabled && [
                formValidation(isNotEmpty),
                formValidation(isLength({ max: 40 })),
                formValidation(isAlphaWithSpaces, locs("errors.invalidSurname")),
              ]
            }
            label={locs("labels.surname")}
          />
          {!disabled && (
            <Field
              name={`${name}.isTitleSuffix`}
              type="text"
              component={Expand}
              props={{
                theme: { baseContainer: theme.baseContainerExpandTitle },
                showLabel: locs("actions.add_title_after"),
                hideLabel: locs("actions.remove_title_after"),
              }}
            />
          )}
          {((disabled && inputValue.titleSuffixId) || inputValue.isTitleSuffix) && (
            <Field
              name={`${name}.titleSuffixId`}
              type="select"
              component={Select}
              props={{
                disabled,
                theme,
                data: formatEnumerationData(titleSuffixes, "desc"),
                showBlankOption: true,
              }}
              label={locs("labels.title_after")}
            />
          )}
        </div>
      </Column>
      {showPosition && (
        <Column columns={2} weights={["1_11", 1]}>
          <Field
            name={`${name}.positionId`}
            type="select"
            component={Select}
            validate={!disabled && [formValidation(isNotEmpty)]}
            props={{
              disabled,
              theme,
              showBlankOption: true,
              data: formatEnumerationData(positions, "desc"),
            }}
            label={locs("labels.position")}
          />
        </Column>
      )}
      <Column columns={2} weights={["1_11", 1]}>
        <Field
          name={`${name}.birthDate`}
          autoComplete="bday"
          type="text"
          component={Date}
          props={{
            disabled,
            theme,
            viewMode: "years" as "years",
            placeholder: locs("placeholders.birth_date"),
          }}
          label={locs("labels.birthDate")}
          validate={
            !disabled && [
              formValidation(isNotEmpty),
              formValidation(isDate),
              formValidation(isAdult),
              formValidation(isAfterDate),
            ]
          }
          normalize={dateFormatter}
        />
      </Column>
      <Column columns={2} weights={["1_11", 1]}>
        <Field
          name={`${name}.email`}
          autoComplete="email"
          type="text"
          component={Input}
          props={{
            disabled,
            theme,
          }}
          validate={!disabled && [formValidation(isNotEmpty), formValidation(isLength({ max: 241 })), formValidation(isEmail)]}
          label={locs("labels.email")}
        />
      </Column>
      <Column columns={2} weights={["1_11", 1]}>
        <Field
          name={`${name}.phone`}
          autoComplete="tel tel-national tel-local"
          type="text"
          component={Input}
          props={{
            disabled,
            theme,
            mask: PHONE_MASK as string[],
            prefix: PHONE_PREFIX,
            placeholder: locs("placeholders.phone"),
          }}
          label={locs("labels.phone")}
          validate={
            !disabled && [formValidation(isNotEmpty), formValidation(isLength({ max: 30 })), formValidation(isPhoneNumber)]
          }
          format={phoneNumberFormatter}
          normalize={phoneNormalizer}
        />
      </Column>
      <div style={{ marginTop: "1rem" }}>
        <Field
          name="marketingAgreement"
          type="checkbox"
          component={Checkbox}
          props={{
            dangerHTML: true,
          }}
          label={locs("texts.marketingAgreement")}
        />
      </div>
    </div>
  );
}
