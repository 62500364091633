import deburr from "lodash/deburr";

export default function isAlphaWithSpaces(value: string): boolean {
  if (!value) {
    return true;
  }

  const deburredValue = deburr(value); // Remove accents
  const regex = /^[-'A-Za-z ]+$/; // the space is intentional to allow spaces, also allow ' and -
  return regex.test(deburredValue);
}
