export default function isLessOrEqualToDefine(max = 0) {
  function isLessOrEqualTo(value: number) {
    if (isNaN(value)) {
      return false;
    }

    return value <= max;
  }

  isLessOrEqualTo.max = max;

  return isLessOrEqualTo;
}
