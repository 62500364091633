import React from "react";
import locs from "../../../../localization";
import Input from "../../../../components/Input";

import defaultTheme from "../theme.scss";

import formValidation from "../../../../validations/formValidation";
import isNotEmpty from "../../../../validations/isNotEmpty";
import isNumber from "../../../../validations/isNumber";
import isGreaterThan from "../../../../validations/isGreaterThan";
import isLength from "../../../../validations/isLength";

import { GAS_CALORIFIC_VALUE, PPAS_EMAIL } from "../../../../constants";
import { Field, InjectedFormProps, WrappedFieldProps } from "redux-form";
import { CalculationGasDetail, SupplyPointConsumption } from "../../../../reducers/types";
import { round } from "lodash";
import isGreaterOrEqualTo from "../../../../validations/isGreaterOrEqualTo";
import isLessOrEqualToDefine from "../../../../validations/isLessOrEqualTo";
import format from "string-template";

interface ConsumptionProps {
  theme?: any;
}

export function gasConsumptionChange({
  values,
  previousValues,
  dispatch,
  props,
  path,
}: {
  values: CalculationGasDetail | SupplyPointConsumption;
  previousValues: CalculationGasDetail | SupplyPointConsumption;
  dispatch;
  props: InjectedFormProps;
  path: string;
}) {
  // changed MWh
  if (values?.consumption !== previousValues?.consumption) {
    const newM3 = round(parseFloat(values?.consumption) / GAS_CALORIFIC_VALUE, 2);
    const currentM3 = parseFloat(values?.consumptionM3) || 0;
    const diff = Math.abs(currentM3 - newM3);

    // do not react to small changes
    if (diff > 1 || currentM3 === 0 || !newM3) {
      dispatch(props.change(`${path}.consumptionM3`, newM3.toFixed(2)));
    }
  }

  // changed m3
  if (values?.consumptionM3 !== previousValues?.consumptionM3) {
    const newMwh = round(parseFloat(values?.consumptionM3) * GAS_CALORIFIC_VALUE, 2);
    const currentMwh = parseFloat(values?.consumption) || 0;
    const diff = Math.abs(currentMwh - newMwh);

    // do not react to small changes
    if (diff > 0.01 || currentMwh === 0 || !newMwh) {
      dispatch(props.change(`${path}.consumption`, newMwh.toFixed(2)));
    }
  }
}

export default function Consumption(props: WrappedFieldProps & ConsumptionProps) {
  const {
    theme = {},
    input: { name },
  } = props;

  return (
    <div>
      <Field
        name={`${name}.consumption`}
        component={Input}
        type="number"
        renderErrorAsHtml
        validate={[
          formValidation(isNotEmpty),
          formValidation(isLength({ max: 10 })),
          formValidation(isNumber),
          formValidation(isGreaterOrEqualTo(0.01), locs("errors.isGreaterOrEqualTo")),
          formValidation(isLessOrEqualToDefine(630), format(locs("errors.corporateConsumptionReached"), { email: PPAS_EMAIL })),
        ]}
        props={{
          placeholder: locs("placeholders.fill_one_the_fields"),
          theme: { ...theme, ...defaultTheme },
          label: locs("labels.your_consumption"),
          suffix: locs("labels.mwh_year"),
        }}
      />
      <Field
        name={`${name}.consumptionM3`}
        component={Input}
        type="number"
        validate={[
          formValidation(isNotEmpty),
          formValidation(isLength({ max: 24 })),
          formValidation(isNumber),
          formValidation(isGreaterThan(0)),
        ]}
        props={{
          theme: { ...theme, ...defaultTheme },
          label: " ",
          suffix: locs("labels.m3_year"),
        }}
      />
    </div>
  );
}
