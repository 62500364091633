import moment, { isMoment } from "moment";

const MIN_DATE = "1.1.1900";

export default function isAfterDate(value) {
  if (!value) return true;
  const date = moment(value, "D.M.YYYY", true);
  if (!date.isValid()) return false;

  return date.isAfter(moment(MIN_DATE, "D.M.YYYY"));
}
